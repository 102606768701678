export const component = async () => {

    let $discussionsCounterValue = 0;
    const $discussionsCounter = $("#discussionsCounter");

    fetch("/dfes-api/UserAPI/GetUserProfileData")
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        let userData = data;
        
        if (userData && userData.data && userData.data.UserUnreadComments) {
            $discussionsCounterValue = userData.data.UserUnreadComments;

            $discussionsCounter.html($discussionsCounterValue);

            if ($discussionsCounterValue == 0 || $discussionsCounterValue == false) {
                $discussionsCounter.hide();
            }
        } else {
            console.error("Invalid response data format");
        }
    })
    .catch(error => {
        console.error("Error fetching user profile data:", error);
    })

};